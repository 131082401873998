import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import FullWidthImage from '../components/FullWidthImage';
import YogaGrid from '../components/YogaGrid';
import YogaPlan from '../components/YogaPlan';
import Newsletter from '../components/Newsletter';

const TestPageTemplate = ({ image, title, items, practice, newsletter }) => {
  const heroImage = getImage(image) || image;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <YogaGrid gridItems={items} />
            <YogaPlan plan={practice} />
            <Newsletter newsletter={newsletter} />
          </div>
        </div>
      </section>
    </div>
  );
};

TestPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  items: PropTypes.array,
  practice: PropTypes.object,
  newsletter: PropTypes.object,
};

const TestPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TestPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        items={frontmatter.items}
        practice={frontmatter.practice}
        newsletter={frontmatter.newsletter}
      />
    </Layout>
  );
};

TestPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TestPage;

export const pageQuery = graphql`
  query TestPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        items {
          title
          icon {
            childImageSharp {
              gatsbyImageData(width: 96, quality: 100, layout: FIXED)
            }
          }
          description
        }
        practice {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
          title
          description
          learn
        }
        newsletter {
          title
          heading
          description
          placeholder
        }
      }
    }
  }
`;
